import React from 'react'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Page from './page'
import { EMAIL } from '../utils/core'
import { Divider, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    flexWrap: 'wrap',
    alignItems: 'center',

    backgroundImage: 'url(https://i.imgur.com/V84SM4v.png)',
    backgroundSize: 'cover',
  },
  backgroundBlur: {
    backgroundImage: 'url("https://i.imgur.com/s9kicBk.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },
  sections: {
    maxWidth: '1200px',
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: '2px',
    marginBottom: theme.spacing(1),
  },
  image: {
    width: '100%',
    maxWidth: '600px',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  type: {
    display: 'flex',
    fontFamily: 'Karla',
    justifyContent: 'center',
    fontSize: 'large',
  },
  subtitle: {
    display: 'flex',
    fontFamily: 'Rubik',
    fontWeight: 100,
    fontSize: 'x-large',
    justifyContent: 'center',
  },
  right: {
    float: 'right',
    maxWidth: '600px',
  },
}))

function Home() {
  const classes = useStyles()

  function content() {
    return (
      <Grid className={classes.backgroundBlur}>
        <Grid container className={classes.container}>
          <Grid item className={classes.sections}>
            <Grid className={classes.image}>
              <img
                src="https://i.imgur.com/ImupkT2.png"
                alt="team work"
                className={classes.image}
              />
            </Grid>

            <Grid className={classes.right}>
              <Typography className={classes.subtitle}>ABOUT US</Typography>
              <Divider className={classes.divider} />
              <br />
              <Typography className={classes.type}>
                Made to Uplift is a free online platform where anyone can sell
                and buy custom stickers to support their communities. We handle
                everything - payment processing, order fulfillment, and customer
                service - so you can focus on connecting with your people.
              </Typography>
              <br />

              <Typography className={classes.subtitle}>OUR FOUNDING</Typography>
              <Divider className={classes.divider} />

              <br />
              <Typography className={classes.type}>
                Made to Uplift was founded in 2021 in Austin, TX. We are a small
                two person passionate, creative, and data-driven team. Our goal
                is to support local communities in raising money for the causes
                they care about.
              </Typography>
              <br />

              {/* I dont know what our contact info is but this is here for when i have that */}
              <Typography className={classes.subtitle}>CONTACT</Typography>
              <Divider className={classes.divider} />

              <br />
              <Typography className={classes.type}>
                For any questions on shipping, production, or a specific
                order,and for press and partnership inquiries or if you're small
                business interested in getting involved, please email {EMAIL}.
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Page name="Home" content={content()} />
}

export default withSnackbar(Home)
